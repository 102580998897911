import * as React from 'react';
import styled from 'styled-components';
import { color } from '@dozuki/web-js/primitives';

const TextContainer = styled.div`
   padding-top: 10px;
   padding-bottom: 10px;
`;

const Text = styled.a`
   font-size: 16px;
   font-weight: normal;
   color: ${color.gray[800]};

   &:visited {
      color: ${color.gray[800]};
   }
`;

const Subsections = styled.div`
   padding-left: 19px;
   border-left: 1px solid ${color.gray[300]};
`;

const TocItem = ({ sectionData }) => {
   const { subSections } = sectionData;

   return (
      <React.Fragment>
         <TextContainer>
            <Text href={`${sectionData.href}`}>{sectionData.title}</Text>
         </TextContainer>
         <Subsections>
            {subSections.map(subSection => (
               <TextContainer key={`subsection-${subSection.title}`}>
                  <Text href={subSection.href}>{subSection.title}</Text>
               </TextContainer>
            ))}
         </Subsections>
      </React.Fragment>
   );
};

export default TocItem;
