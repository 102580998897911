import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { tabletDetected, mobileDetected } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import { LinkOut } from '@core-ds/icons/16';
import { Initializer } from 'Shared/chakra-initialize';

onDomReady(function () {
   when($E('#edit-nav-link, #translate-nav-link'), function (link) {
      link = link.get('href');
      $$('.editSectionLink').each(function (input) {
         input.addEvent('click', function (ev) {
            ev.stop();
            let offset = input.get('data-offset');
            window.location = link + '?offset=' + offset;
         });
      });
   });

   const initPagination = function (container, handler) {
      let wrapper = container.parentElement;
      let pageButtons = wrapper.getChildren('.paginate');
      pageButtons.addEvent('click', function (ev) {
         wrapper.getChildren('.progress').show();
         wrapper.getChildren('.paginationInfo').hide();

         ev.stop();

         let offset = parseInt(container.get('data-offset'), 10),
            limit = parseInt(container.get('data-limit'), 10),
            wikiid = container.get('data-wikiid'),
            total = parseInt(container.get('data-total'), 10);

         if (ev.target.hasClass('previous')) {
            offset -= limit;
         } else {
            offset += limit;
         }

         container.set('data-offset', offset);
         new Request.AjaxIO(handler, {
            onSuccess: function (data) {
               wrapper.getChildren('.progress').hide();
               wrapper.getChildren('.paginationInfo').show();
               wrapper
                  .getElement('.currentPage')
                  .set('text', Math.ceil(data.offset / data.limit) + 1);
               container.set('html', data.html);

               if (data.offset + data.limit >= total) {
                  wrapper.getChildren('.paginate.next').hide();
               } else {
                  wrapper.getChildren('.paginate.next').show();
               }

               if (data.offset <= 0) {
                  wrapper.getChildren('.paginate.previous').hide();
               } else {
                  wrapper.getChildren('.paginate.previous').show();
               }
            },
         }).send(wikiid, limit, offset);
      });
   };

   when($('guideItemList'), function (container) {
      initPagination(container, 'getGuideItems');
   });

   when($('diagramItemList'), function (container) {
      initPagination(container, 'getDiagramItems');
   });

   when($('suppliers'), function (el) {
      if ($$('.wikiInfo').length === 0) {
         el.addClass('fullWidth');
      }
   });

   $$('.topicHeaderTextToggle').each(function (el) {
      el.addEvent('click', function (ev) {
         ev.stop();
         $$('.topicHeaderText').toggleClass('hidden');
      });
   });

   /**
    * Highlight the table of contents link whose heading is currently visible.
    */
   when($('sidebar-wiki-toc'), function (toc) {
      (function initialize() {
         if (toc.hasClass('js-dynamic-toc')) {
            generateToc();
         }

         // Animate the scrolling for the window.
         toc.animateLinkedHashChanges(0.3, onHeadingVisible);

         // Highlight visible headings in the ToC
         const headings = $$('#mainBody [id^="Section_"]');
         let scrollSpy = new ScrollSpy({
            target: headings,
            onEnter: function (_, heading) {
               onHeadingVisible(heading);
            },
         });
         scrollSpy.runOnce();

         // Scroll the clicked link into the middle of the sidebar.
         toc.addEvent('click:relay(a)', function (ev) {
            TweenMax.to(toc, 0.3, {
               scrollTo: ev.target.offsetTop - toc.getSize().y * 0.5,
            });
         });

         tableOfContentsGeneratedResolved(toc);
      })();

      function generateToc() {
         let ul = toc.getElement('ul');
         if (!ul) {
            ul = new Element('ul');
            toc.adopt(
               new Element('div', {
                  class: 'toc',
               }).adopt(ul)
            );
         }

         let detailsToc = ul.get('html');
         ul.empty();

         let lastH2 = null;
         let guideSection = null;
         let newSections = $$('.js-dynamic-toc-section, #Wiki_Details');
         for (let i = 0; i < newSections.length; i++) {
            let heading = newSections[i];

            if (heading.id == 'Wiki_Details') {
               if (detailsToc) {
                  ul.adopt(
                     new Element('li').adopt(
                        new Element('a', {
                           text: _js('Details'),
                           href: '#Wiki_Details',
                           class: 'toc-section',
                        }),
                        new Element('ul', {
                           html: detailsToc,
                        })
                     )
                  );
               }
               lastH2 = null;
               continue;
            }

            let firstId = heading.id.split(' ')[0];
            let li = new Element('li').adopt(
               new Element('a', {
                  // Text should match the section header title.
                  // i.e. "Section_Related_Pages" -> "Related Pages"
                  text: firstId.replace(/^Section_/, '').replace(/_/g, ' '),
                  href: '#' + heading.id,
                  class: heading.tagName === 'H3' ? 'toc-subsection' : 'toc-section',
               })
            );

            if (heading.tagName === 'H3' && lastH2 !== null) {
               lastH2.adopt(new Element('ul').adopt(li));
            } else {
               lastH2 = li;
               ul.adopt(li);
               if (heading.hasClass('js-guide-section')) {
                  guideSection = li;
               }
            }
         }

         // Because of how Augments are rendered, we set up a placeholder Guide
         // Section at the top of the page. If every guide augment fails to
         // render we should remove this section from the page and TOC.
         if (
            guideSection &&
            guideSection.getElements('ul').length === 0 &&
            $$('.highlight-guides').length === 0
         ) {
            guideSection.remove();
            $$('.js-guide-section')[0].remove();
         }
      }

      /**
       * When a heading is visible in the wiki body, highlight the ToC link
       * associated with it. Also, scroll the sidebar if necessary.
       */
      function onHeadingVisible(heading) {
         // Remove the "scrolled" class from all links and get the link
         // that corresponds to the visible heading.
         let scrolled = toc
            .getElements('a')
            .removeClass('scrolled')
            .filter('[href$="#' + heading.get('id') + '"]');

         // Apply a css class to the link and all of its parent links.
         if (scrolled.length) {
            let scrolledLink = scrolled[0];

            scrolledLink.getParents('li').getElement('> a').addClass('scrolled');

            scrollLinkIntoSidebar(scrolledLink);
         }
      }

      /**
       * Auto-scroll the sidebar so that the highlighted link is always
       * visible.
       */
      function scrollLinkIntoSidebar(link) {
         if (toc.match(':hover') && !mobileDetected() && !tabletDetected()) {
            // Don't auto-scroll the ToC if the user is hovering over it,
            // because that likely means they are scrolling it manually.
            // Disable this feature on mobile devices because iOS leaves
            // :hover events on elements that the user isn't interacting with.
            return;
         }

         let position = link.offsetTop - toc.getScroll().y;
         let height = toc.getSize().y;

         // Scroll the ToC if the highlighted link is close to offscreen.
         if (position < height * 0.3) {
            TweenMax.to(toc, 0.1, {
               scrollTo: link.offsetTop - height * 0.3,
            });
         }

         if (position > height * 0.7) {
            TweenMax.to(toc, 0.1, {
               scrollTo: link.offsetTop - height * 0.7,
            });
         }
      }
   });

   // Add Core Icons arrow to black link headers.
   document
      .querySelectorAll('.headerContainer > h2.header a, .headerContainer > h3.header a')
      .forEach(el => {
         const LinkOutIcon = Initializer(LinkOut);
         const headerText = el.innerText;
         const root = createRoot(el);
         root.render(<LinkOutIcon />);
         el.insertAdjacentText('beforebegin', headerText);
      });

   // Add the headerLinkArrow class to each arrow we created.
   document
      .querySelectorAll(
         '.headerContainer > h2.header a > svg, .headerContainer > h3.header a > svg'
      )
      .forEach(el => {
         el.classList.add('headerLinkArrow');
      });
});

// This is here to give the mobile TOC a signal that we are done generating
// the desktop TOC and thus it can parse the dom to make the mobile copy.
// eslint-disable-next-line no-var
export var tableOfContentsGenerated = (window.tableOfContentsGenerated = new Promise(function (
   resolve
) {
   window.tableOfContentsGeneratedResolved = resolve;
}));
